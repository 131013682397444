import {
    any,
    array,
    arrayOf,
    bool,
    func,
    instanceOf,
    number,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import React, { useMemo } from 'react';

import styles from './Grid.css';

/**
 * Renders a grid lines on the svg
*/
const Grid = ({
    children,
    hideXTicks,
    hideYTicks,
    scale,
    xLineClassName,
    xLineStyle,
    xTicks,
    yLineClassName,
    yLineStyle,
    yTicks,
}) => {
    const [xLinePositions, yLinePositions] = useMemo(() => {
        const yLines = yTicks || scale.y.ticks();
        const xLines = xTicks || scale.x.ticks();

        return [
            xLines.map(line => scale.x(line)),
            yLines.map(line => scale.y(line)),
        ];
    }, [scale, xTicks, yTicks]);

    if (!scale) {
        return children;
    }

    return (
        <React.Fragment>
            {!hideXTicks && xLinePositions.map(position => (
                <line
                    className={`${styles.Grid__line} ${xLineClassName}`}
                    key={position}
                    style={xLineStyle}
                    x1={position}
                    x2={position}
                    y1="0"
                    y2="100%"
                />
            ))}
            {!hideYTicks && yLinePositions.map(position => (
                <line
                    className={`${styles.Grid__line} ${yLineClassName}`}
                    key={position}
                    style={yLineStyle}
                    x1="0"
                    x2="100%"
                    y1={position}
                    y2={position}
                />
            ))}
        </React.Fragment>
    );
};

Grid.defaultProps = {
    children: null,
    hideXTicks: false,
    hideYTicks: false,
    xLineClassName: null,
    xLineStyle: null,
    yLineClassName: null,
    yLineStyle: null,
};

Grid.propTypes = {
    children: any,
    /**  Hides xAxis grid lines when true */
    hideXTicks: bool,
    /**  Hides yAxis grid lines when true */
    hideYTicks: bool,
    /** `scale` is used in memoized conditions, ensure reference does not change needlessly */
    scale: shape({
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        x: func,
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        y: func,
    }).isRequired,
    /** className for the xAxis lines */
    xLineClassName: string,
    /** style object for the xAxis lines */
    xLineStyle: object,
    /** Array of tick values xAxis.
     * `xTicks` is used in memoized conditions, ensure reference does not change needlessly
     */
    xTicks: arrayOf(oneOfType([number, instanceOf(Date)])),
    /** className for the yAxis lines */
    yLineClassName: string,
    /** style object for the yAxis lines */
    yLineStyle: object,
    /** Array of tick values yAxis.
     * `yTicks` is used in memoized conditions, ensure reference does not change needlessly
     */
    yTicks: array,
};

export default Grid;
