import { area, curveLinear } from 'd3';
import {
    any,
    arrayOf,
    func,
    object,
    shape,
    string,
} from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Renders data as a filled line within the chart
 * https://github.com/d3/d3-shape#areas
 * If hovering is needed, behind the area, render a transparent Line component and use that for determining the value.
 * @returns {SVGPathElement}
 */
const Area = ({
    className,
    curve,
    data,
    scale,
    style,
    xExtractor,
    yExtractor,
}) => {
    const path = useMemo(() => {
        if (!scale) {
            return null;
        }

        // Dont compute if the data is empty
        if (!Array.isArray(data) || !data.length) {
            return null;
        }

        const yMax = scale.y.range()[0];

        const fillPathGenerator = area()
            .curve(curve)
            .x((...params) => scale.x(xExtractor(...params)))
            .y0(yMax)
            .y1((...params) => scale.y(yExtractor(...params)));

        return fillPathGenerator(data);
    }, [curve, data, scale]);

    return <path className={className} d={path} style={style} />;
};

Area.defaultProps = {
    className: null,
    curve: curveLinear,
    data: [],
    style: null,
    xExtractor: ([x]) => x,
    yExtractor: ([, y]) => y,
};

Area.propTypes = {
    className: string,
    /** [D3 Curve](https://github.com/d3/d3-shape#curves).
     * `curve` is used in memoized conditions, ensure reference does not change needlessly
    */
    curve: func,
    /** Array of any type.
     * `data` is used in memoized conditions, ensure reference does not change needlessly
     */
    data: arrayOf(any),
    /** `scale` is used in memoized conditions, ensure reference does not change needlessly */
    scale: shape({
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        x: func,
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        y: func,
    }).isRequired,
    style: object,
    /** Function that extracts the y value out of any point in the data array.
     *  @param {any} currentValue the current iterated value of the array.
     *  @param {int} index the current index of the value in the array.
     *  @param {array} array the array being iterated.
     */
    xExtractor: func,
    /** Function that extracts the y value out of any point in the data array.
     *  @param {any} currentValue the current iterated value of the array.
     *  @param {int} index the current index of the value in the array.
     *  @param {array} array the array being iterated.
     */
    yExtractor: func,
};

export default Area;
