/**
 * Gets the hovered x point of the svg from a Touch Event and Scale
 * @param {TouchEvent} event
 * @param {{ x: Function, y: Function }} scale
 * @returns {*} x value of the chart that is hovered
 */
function onTouchMove(event, scale) {
    if (!event || !scale) {
        return null;
    }

    const clientWidth = Math.abs(scale.x.range().reduce((total, current) => total - current, 0));

    const boundingClientRectangle = event.currentTarget.getBoundingClientRect();

    const width = boundingClientRectangle.right - boundingClientRectangle.left;

    // Get touch positions relative to the rectangle
    const x = Math.min(
        Math.max(
            (event.touches[0].clientX - boundingClientRectangle.left),
            0,
        ),
        width,
    );

    if (x < 0) {
        return null;
    }

    return scale.x.invert((x / width) * clientWidth);
}

export default onTouchMove;
