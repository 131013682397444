import { scaleLinear } from 'd3';
import { func, number, shape } from 'prop-types';
import React, { createContext, useMemo } from 'react';

function generateScale(max, min, scale, mutator) {
    if (!Number.isFinite(max) || !Number.isFinite(min) || !scale) {
        return null;
    }

    return mutator(scale().domain([min, max]));
}

/** Generates an x and y scale with momoization using React Context */
const Scale = ({
    children,
    height,
    onChange,
    width,
    xMax,
    xMin,
    xScale,
    xMutator,
    yMax,
    yMin,
    yScale,
    yMutator,
}) => {
    const Context = useMemo(createContext, []);

    const scale = useMemo(
        () => {
            const x = generateScale(xMax, xMin, xScale, xMutator);

            if (x) {
                x.range([0, width]);
            }

            const y = generateScale(yMax, yMin, yScale, yMutator);

            if (y) {
                y.range([height, 0]);
            }

            onChange({ x, y });

            return { x, y };
        },
        [
            height,
            width,
            xMax,
            xMin,
            xMutator,
            xScale,
            yMax,
            yMin,
            yMutator,
            yScale,
        ],
    );

    return (
        <Context.Provider value={scale}>
            <Context.Consumer>
                {children}
            </Context.Consumer>
        </Context.Provider>
    );
};

Scale.defaultProps = {
    height: 400,
    width: 700,
    onChange: scale => scale,
    xMax: null,
    xMin: null,
    xMutator: scale => scale,
    xScale: scaleLinear,
    yMax: null,
    yMin: null,
    yMutator: scale => scale,
    yScale: scaleLinear,
};

Scale.propTypes = {
    /**
     * @param {Object} scale { x: D3Scale, y: D3Scale }
     * @returns {Element} HTML Element
     */
    children: func.isRequired,
    /** Height of chart */
    height: number,
    /** Callback function that triggers on change of scale
     * @param {D3Scale} scale
    */
    onChange: func,
    /** Width of chart */
    width: number,
    /** Maximum value on x axis */
    xMax: number,
    /** Minimum value on x axis */
    xMin: number,
    /** Function that can be used to mutate or override the x scale
     * @param {D3Scale} scale
     * @returns {D3Scale}
    */
    xMutator: func,
    /** [D3 Scale](https://github.com/d3/d3-scale) for x axis. */
    xScale: func,
    yMax: number,
    yMin: number,
    /** Function that can be used to mutate or override the y scale
     * @param {D3Scale} scale
     * @returns {D3Scale}
    */
    yMutator: func,
    /** [D3 Scale](https://github.com/d3/d3-scale) for y axis. */
    yScale: func,
};


const ScaleShape = shape({
    /** [D3 Scale](https://github.com/d3/d3-scale). */
    x: func,
    /** [D3 Scale](https://github.com/d3/d3-scale). */
    y: func,
}).isRequired;

export default Scale;
export { ScaleShape };
