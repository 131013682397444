import React from 'react';
import { string } from 'prop-types';

/**
 * [`<svg>`](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/svg) element wrapper
 * that provides default props to match the chart library.
*/
const Chart = (props) => <svg {...props} />;

Chart.defaultProps = {
    className: 'svg-content-responsive',
    preserveAspectRatio: 'xMinYMin meet',
    viewBox: '0 0 700 400',
};

Chart.propTypes = {
    className: string,
    preserveAspectRatio: string,
    /** 0 0 width height */
    viewBox: string,
};

export default Chart;
