import { histogram } from 'd3';
import { any, arrayOf, func } from 'prop-types';
import React, { createContext, useMemo } from 'react';

/** React Context API Component for generating [D3 Histogram Bins](https://github.com/d3/d3-array/blob/v1.2.4/README.md#histograms)
 */
const Histogram = ({
    accumulateExtractor,
    binMutator,
    children,
    data,
    extractor,
    max,
    min,
}) => {
    const Context = useMemo(createContext, []);

    const bins = useMemo(
        () => {
            const domain = [min, max];

            const values = data.map(extractor);
            if (max === null) {
                domain[1] = Math.max(...values);
            }

            if (min === null) {
                domain[0] = Math.min(...values);
            }

            const binGenerator = binMutator(
                histogram()
                    .value(extractor)
                    .domain(domain),
            );

            return binGenerator(data).map(bin => ({
                data: bin,
                total: bin.reduce(
                    (total, current, ...otherParams) => (total + accumulateExtractor(current, ...otherParams)),
                    0,
                ),
            }));
        },
        [
            accumulateExtractor,
            binMutator,
            data,
            extractor,
            max,
            min,
        ],
    );

    return (
        <Context.Provider value={{ bins }}>
            <Context.Consumer>
                {children}
            </Context.Consumer>
        </Context.Provider>
    );
};

Histogram.defaultProps = {
    accumulateExtractor: () => 1,
    binMutator: generator => generator,
    children: null,
    data: [],
    extractor: value => value,
    max: null,
    min: null,
};

Histogram.propTypes = {
    /** Extracts the value from a data point within the array to be used as it's accumulation weighting.
     * @returns {Number} Weighting (default: 1)
    */
    accumulateExtractor: func,
    /** Allows for the binGenerator function to be altered before iterating the data.
     * @returns {Function}
    */
    binMutator: func,
    /**
     * @param {Array} bins Array of bins: { data: D3Bin, total: number }
     * @returns {Element} HTML Element
     */
    children: func,
    /** Array of any type.
     * `data` is used in memoized conditions, ensure reference does not change needlessly
     */
    data: arrayOf(any),
    /** Extracts the value from a data point within the array to be used as it's grouped value.
     * @returns {*} Default: (x) => x[0]
    */
    extractor: func,
    /** Max value for the data */
    max: any,
    /** Max value for the data */
    min: any,
};

export default Histogram;
