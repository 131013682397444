import {
    any,
    func,
    instanceOf,
    number,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import React from 'react';

import styles from './VerticalLine.css';

/** Renders a vertical line on the chart from the top to the bottom.
 * Useful for rendering a line when hovering over the chart.
*/
const VerticalLine = ({
    className,
    scale,
    style,
    sync,
}) => {
    if (!sync) {
        return null;
    }

    const xPostion = scale.x(sync);

    return (
        <line
            className={`${styles.VerticalLine__line} ${className}`}
            style={style}
            x1={xPostion}
            x2={xPostion}
            y1="0"
            y2="100%"
        />
    );
};

VerticalLine.defaultProps = {
    className: null,
    style: null,
    sync: null,
};

VerticalLine.propTypes = {
    className: string,
    /** `scale` is used in memoized conditions, ensure reference does not change needlessly */
    scale: shape({
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        x: func,
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        y: func,
    }).isRequired,
    style: object,
    /** Any valid value on the x scale which is then used to get the x position of the chart */
    sync: oneOfType([object, number, instanceOf(Date)]),
};

export default VerticalLine;
