import { curveLinear, line } from 'd3';
import {
    any,
    arrayOf,
    func,
    instanceOf,
    number,
    object,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import React, { useLayoutEffect, useMemo, useRef } from 'react';

/**
 * Renders data as a line within the chart
 * https://github.com/d3/d3-shape#lines
 * @returns {SVGPathElement}
 */
const Line = ({
    className,
    curve,
    data,
    scale,
    style,
    sync,
    valueExtractor,
    xExtractor,
    yExtractor,
}) => {
    const refPath = useRef();

    const path = useMemo(
        () => {
            if (!scale) {
                return null;
            }

            // Dont compute if the data is empty
            if (!Array.isArray(data) || !data.length) {
                return null;
            }

            // Initialise area or data based on fill value
            const linePathGenerator = line()
                .curve(curve)
                .x((...params) => scale.x(xExtractor(...params)))
                .y((...params) => scale.y(yExtractor(...params)));

            return linePathGenerator(data);
        },
        [curve, data, scale],
    );

    useLayoutEffect(
        () => {
            valueExtractor(
                data,
                scale,
                refPath,
                sync,
                xExtractor,
                yExtractor,
            );
        },
        [
            data,
            refPath,
            scale,
            sync,
        ],
    );

    return (
        <path
            className={className}
            d={path}
            fill="transparent"
            ref={refPath}
            style={style}
        />
    );
};

Line.defaultProps = {
    className: null,
    curve: curveLinear,
    data: [],
    style: null,
    sync: null,
    valueExtractor: () => {},
    xExtractor: ([x]) => x,
    yExtractor: ([, y]) => y,
};

Line.propTypes = {
    className: string,
    /** [D3 Curve](https://github.com/d3/d3-shape#curves).
     * `curve` is used in memoized conditions, ensure reference does not change needlessly
    */
   curve: func,
    /** Array of any type.
     * `data` is used in memoized conditions, ensure reference does not change needlessly
     */
    data: arrayOf(any),
    /** `scale` is used in memoized conditions, ensure reference does not change needlessly */
    scale: shape({
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        x: func,
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        y: func,
    }).isRequired,
    style: object,
    /** Changing this value triggers the valueExtractor to be triggered */
    sync: oneOfType([object, number, instanceOf(Date)]),
    /** Function that extracts the dataPoint using the data and the sync value.
     * Generally calls lineBisector.
     * @param {array} data
     * @param {Object} scale
     * @param {ReactRef} refPath
     * @param {*} sync
     * @param {Function} xExtractor
     * @param {Function} yExtractor
     */
    valueExtractor: func,
    /** Function that extracts the y value out of any point in the data array.
     *  @param {any} currentValue the current iterated value of the array.
     *  @param {int} index the current index of the value in the array.
     *  @param {array} array the array being iterated.
     */
    xExtractor: func,
    /** Function that extracts the y value out of any point in the data array.
     *  @param {any} currentValue the current iterated value of the array.
     *  @param {int} index the current index of the value in the array.
     *  @param {array} array the array being iterated.
     */
    yExtractor: func,
};

export default Line;
