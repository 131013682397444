import {
    any,
    func,
    object,
    string,
} from 'prop-types';
import React, { useMemo } from 'react';

import styles from './XTicks.css';

/** Crude implmentation of axis using html instead of svg elements.
 * Only use as a last resort.
*/
const XTicks = ({
    children,
    contentClassName,
    contentStyle,
    format,
    // Position of the ticks
    position,
    scale,
    style,
    tickStyle,
}) => {
    const ticks = useMemo(
        () => {
            const newTicks = scale.ticks();
            const xMax = scale.range()[1];

            return newTicks.map((tick) => {
                const leftOffset = scale(tick) / xMax * 100;
                let transform = null;
                if (leftOffset) {
                    transform = 'translateX(-50%)';
                }

                if (leftOffset === 100) {
                    transform = 'translateX(-100%)';
                }

                return {
                    left: `${leftOffset}%`,
                    text: format(tick),
                    transform,
                };
            });
        },
        [scale, format],
    );

    const flexDirection = (position !== 'top') ? 'column-reverse' : 'column';

    return (
        <div className={styles.XTicks__container} style={{ flexDirection, ...style }}>
            <div className={`${styles.XTicks__content} ${contentClassName}`} style={contentStyle}>
                {ticks.map(({ left, text, transform }) => (
                    <div
                        className={styles.XTicks__tick}
                        key={text}
                        style={{ left, transform, ...tickStyle }}
                    >
                        {text}
                    </div>
                ))}
                <div className={styles.XTicks__placeholder}>
                    {format(scale(scale.range()[1]))}
                </div>
            </div>
            <div className={styles.XTicks__content}>
                {children}
            </div>
        </div>
    );
};

XTicks.defaultProps = {
    children: null,
    contentClassName: null,
    contentStyle: null,
    format: tick => tick,
    position: 'bottom',
    style: null,
    tickStyle: null,
};

XTicks.propTypes = {
    children: any,
    contentClassName: string,
    contentStyle: object,
    format: func,
    position: string,
    scale: func.isRequired,
    style: object,
    tickStyle: object,
};

export default XTicks;
