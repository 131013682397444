import {
    any,
    func,
    object,
    string,
} from 'prop-types';
import React, { useMemo } from 'react';

import styles from './YTicks.css';

/** Crude implmentation of axis using html instead of svg elements.
 * Only use as a last resort.
*/
const YTicks = ({
    children,
    contentClassName,
    contentStyle,
    format,
    // Position of the ticks
    position,
    scale,
    style,
    tickStyle,
}) => {
    const ticks = useMemo(
        () => {
            const newTicks = scale.ticks();
            const yMax = scale.range()[0];

            return newTicks.map((tick, ...otherParams) => {
                const topOffset = scale(tick) / yMax * 100;
                let transform = null;
                if (topOffset) {
                    transform = 'translateY(-50%)';
                }

                if (topOffset === 100) {
                    transform = 'translateY(-100%)';
                }

                return {
                    text: format(tick, ...otherParams),
                    top: `${topOffset}%`,
                    transform,
                };
            });
        },
        [scale, format]
    );

    const flexDirection = (position !== 'right') ? 'row' : 'row-reverse';

    return (
        <div className={styles.YTicks__container} style={{ flexDirection, ...style }}>
            <div className={contentClassName} style={contentStyle}>
                {ticks.map(({ text, top, transform }) => (
                    <div
                        className={styles.YTicks__tick}
                        key={text}
                        style={{ top, transform, ...tickStyle }}
                    >
                        {text}
                    </div>
                ))}
                <div className={styles.YTicks__placeholder}>
                    {format(scale.domain()[1])}
                </div>
            </div>
            <div className={styles.YTicks__childrenContainer}>
                {children}
            </div>
        </div>
    );
};

YTicks.defaultProps = {
    children: null,
    contentClassName: null,
    contentStyle: null,
    format: tick => tick,
    position: 'left',
    style: null,
    tickStyle: null,
};

YTicks.propTypes = {
    children: any,
    contentClassName: string,
    contentStyle: object,
    format: func,
    position: string,
    scale: func.isRequired,
    style: object,
    tickStyle: object,
};

export default YTicks;
