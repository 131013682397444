import React from 'react';
import {
    any,
    func,
    instanceOf,
    number,
    object,
    shape,
    string,
} from 'prop-types';

/**
 * Renders a d3
 * @returns {SVGRect}
 */
const Bar = ({
    className,
    data,
    scale,
    style,
    total,
}) => {
    const maxHeight = scale.y.range()[0];
    const yPosition = scale.y(total);
    const height = maxHeight - yPosition;

    return (
        <rect
            className={className}
            height={height}
            style={style}
            x={scale.x(data.x0)}
            y={yPosition}
            width={scale.x(data.x1 - data.x0)}
        />
    );
};

Bar.defaultProps = {
    className: null,
    // data,
    style: null,

}

Bar.propTypes = {
    className: string,
    /** [D3 Histogram Bin](https://github.com/d3/d3-array/blob/v1.2.4/README.md#histograms) */
    data: object.isRequired,
    scale: shape({
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        x: func,
        /** [D3 Scale](https://github.com/d3/d3-scale). */
        y: func,
    }).isRequired,
    style: object,
    total: number.isRequired,
}

export default Bar;
