/**
 * Gets the hovered x point of the svg from the Event and Scale
 * @param {MouseEvent} event
 * @param {{ x: Function, y: Function }} scale
 * @returns {*} x value of the chart that is hovered
 */
function onMouseMove(event, scale) {
    if (!event || !scale) {
        return null;
    }

    const clientWidth = Math.abs(scale.x.range().reduce((total, current) => total - current, 0));

    const boundingClientRectangle = event.currentTarget.getBoundingClientRect();

    const width = boundingClientRectangle.right - boundingClientRectangle.left;

    // Get mouse positions relative to the rectangle
    const x = event.clientX - boundingClientRectangle.left;

    if (x < 0) {
        return null;
    }

    return scale.x.invert((x / width) * clientWidth);
}

export default onMouseMove;
