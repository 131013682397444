import { ascending } from 'd3';

const HOVERED_DISTANCE_THRESHOLD = 0.1;

function bisector(extractor, array, sync, low, high, compare = ascending) {
    if (low == null) {
        low = 0;
    }
    if (high == null) {
        high = array.length;
    }

    while (low < high) {
        // eslint-disable-next-line no-bitwise
        const mid = low + high >>> 1;

        const value = extractor(array[mid], mid, array, sync);
        if (compare(value, sync) < 0) {
            low = mid + 1;
        } else {
            high = mid;
        }
    }

    return low;
}

function lineBisector(
    data,
    scale,
    svgRef,
    sync,
    xExtractor,
    yExtractor,
) {
    if (!sync) {
        return null;
    }

    if (!data || !data.length) {
        return null;
    }

    if (!xExtractor || !yExtractor) {
        return null;
    }

    // Get the left closest (insertion) point within the data to the sync value
    const closestIndex = bisector(xExtractor, data, sync);

    // If the closest point exceeds the bounds of the data, exit
    if (closestIndex < 0
        || closestIndex >= data.length - 1
    ) {
        return null;
    }

    if (!scale) {
        return null;
    }

    // Convert the sync value into a scaled value
    const scaledSync = scale.x(sync);

    const closestDataPoint = [
        xExtractor(data[closestIndex], closestIndex, data, sync),
        yExtractor(data[closestIndex], closestIndex, data, sync),
        closestIndex,
    ];

    // Calculate the pixel difference between the closest point and the sync values
    const calculatedDifference = Math.abs(
        scale.x(closestDataPoint[0]) - scaledSync,
    );

    if (calculatedDifference <= HOVERED_DISTANCE_THRESHOLD) {
        return closestDataPoint;
    }

    if (!svgRef || !svgRef.current) {
        return null;
    }

    const closestPathPoint = [
        scale.x(closestDataPoint[0]),
        scale.y(closestDataPoint[1]),
    ];

    let lengthEnd = svgRef.current.getTotalLength();
    let lengthStart = 0;
    let pathPoint = null;
    let iterations = 0;
    let currentLength = 0;

    const isReverse = (svgRef.current.getPointAtLength(0).x > svgRef.current.getPointAtLength(svgRef.current.getTotalLength()).x);

    do {
        // Get the middle point
        currentLength = (lengthEnd + lengthStart) / 2;

        pathPoint = svgRef.current.getPointAtLength(currentLength);

        if (scaledSync < pathPoint.x) {
            if (isReverse) {
                lengthStart = currentLength;
            } else {
                lengthEnd = currentLength;
            }
        } else if (isReverse) {
            lengthEnd = currentLength;
        } else {
            lengthStart = currentLength;
        }

        // Increase iteration
        iterations += 1;
        if (iterations > 30) {
            pathPoint = {
                x: null,
                y: null,
            };

            break;
        }
    } while (Math.abs(pathPoint.x - scaledSync) > HOVERED_DISTANCE_THRESHOLD);

    let tempPathPoint = [pathPoint.x, pathPoint.y];
    if ((tempPathPoint[0] === null) || (tempPathPoint[1] === null)) {
        tempPathPoint = closestPathPoint;
    }

    return [
        scale.x.invert(tempPathPoint[0]),
        scale.y.invert(tempPathPoint[1]),
        closestIndex,
    ];
}

export default lineBisector;
